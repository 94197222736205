import { AnimationControls, motion, useAnimation } from "framer-motion";
import "../../styles/faq/faq.css";
import useResponse from "../../hooks/useResponse";
import FaqFooter from "../../components/faq/FaqFooter";
import { faqs } from "../../contexts/faq";
import { IFaqs } from "../../@types/type";
import { useEffect, useState } from "react";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const [page, setPage] = useState<number>(1);
  const [isChangePage, setIsChangePage] = useState(false);

  const desktopContentAnimation = useAnimation();

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();

  useEffect(() => {
    if (isChangePage) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  useEffect(() => {
    (async () => {
      if (desktopContentAnimation && isDesktop) {
        desktopContentAnimation.set({ opacity: 0, y: 50 });

        await desktopContentAnimation.start({ opacity: 1, y: 0 });
      }
    })();
  }, [selectedIndex]);

  const getRange = () => {
    let faqRange;

    const start = page * 10 - 10;
    const end = page * 10 - 1;

    faqRange = [start, end];

    return faqRange;
  };

  const onChangeSelectedIndex = (index: number) => {
    if (selectedIndex === index) {
      setSelectedIndex(undefined);
    } else {
      setSelectedIndex(index);
    }
  };

  const onChangePage = (_page: number) => {
    if (!isChangePage) {
      setIsChangePage(true);
    }

    setPage(_page);
  };

  return (
    <div className="faq-container">
      {!isMobile && <div className="faq-title-text font-bold">FAQ</div>}
      <div className="faq-content">
        <div className="faq-content-inner">
          <div className="faq-box-container">
            {faqs.map((faq: IFaqs, i) => {
              if (getRange()[0] <= i && getRange()[1] >= i) {
                return (
                  <motion.div
                    className={`faq-box cursor-pointer ${selectedIndex === i && "faq-box-active"}`}
                    key={faq.title}
                    onClick={() => {
                      onChangeSelectedIndex(i);

                      if (isDesktop) {
                      }
                    }}
                  >
                    <div className="faq-box-title-container ">
                      <>
                        <div className="faq-mark font-bold">Q</div>
                        <div
                          className={`faq-box-title-text ${
                            selectedIndex === i ? "font-bold" : "font-regular"
                          }`}
                        >
                          {faq.title}
                        </div>
                      </>
                    </div>
                    {!isDesktop && selectedIndex === i && (
                      <div className="faq-box-content-container">
                        <div className="faq-box-content-inner font-light">{faq.content}</div>
                      </div>
                    )}
                  </motion.div>
                );
              }
            })}

            <div className="faq-pagination-container">
              <div
                className={`faq-pagination-inner cursor-pointer ${
                  page === 1 && "faq-pagination-active"
                }`}
                onClick={() => {
                  onChangePage(1);
                }}
              >
                <div className="faq-pagination-inner-text font-regular">1</div>
              </div>

              <div
                className={`faq-pagination-inner cursor-pointer ${
                  page === 2 && "faq-pagination-active"
                }`}
                onClick={() => {
                  onChangePage(2);
                }}
              >
                <div className="faq-pagination-inner-text font-regular">2</div>
              </div>
            </div>
          </div>

          {isDesktop && (
            <motion.div
              className="faq-content-container"
              initial={{ opacity: 0, y: 50 }}
              animate={desktopContentAnimation}
              transition={{ duration: 0.5 }}
            >
              <div className="faq-content-inner1 font-medium">
                {selectedIndex !== undefined && faqs[selectedIndex].title}
              </div>

              <div className="faq-content-inner2 font-light">
                {selectedIndex !== undefined && faqs[selectedIndex].content}
              </div>
            </motion.div>
          )}
        </div>
      </div>
      <FaqFooter footerMode={"dark"} textMode={"dark"} active={1} move={move} />
    </div>
  );
};
