import { Navigate, useNavigate } from "react-router-dom";
import useResponse from "../../hooks/useResponse";
import "../../styles/default/header.css";
import IntroNavigation from "./IntroNavigation";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  activePage: number;
  goToTop: () => void;
};

export default ({ activePage, goToTop }: Props) => {
  const navigate = useNavigate();
  const { isDesktop } = useResponse();
  const { isScrolling } = useScrolling();

  const whiteLogo = [1, 3];
  const blackLogo = [2, 4];

  const whiteNavi = [1, 3];
  const blackNavi = [2, 4];

  const whiteBg = [2, 4];
  const blackBg = [1, 3];

  const getLogo = () => {
    if (isDesktop) {
      if (whiteLogo.includes(activePage)) {
        return "white-header-logo";
      } else if (blackLogo.includes(activePage)) {
        return "black-header-logo";
      } else {
        return "white-header-logo";
      }
    } else {
      if (whiteLogo.includes(activePage)) {
        return "phone-white-header-logo";
      } else if (blackLogo.includes(activePage)) {
        return "phone-black-header-logo";
      } else {
        return "phone-white-header-logo";
      }
    }
  };

  const getBackground = () => {
    if (isDesktop && activePage === 1) {
      return "trans-bg";
    }
    if (whiteBg.includes(activePage)) {
      return "white-bg";
    } else if (blackBg.includes(activePage)) {
      return "black-bg";
    } else {
      return "black-bg";
    }
  };

  const getNavi = () => {
    if (isDesktop) {
      if (whiteNavi.includes(activePage)) {
        return "white";
      } else if (blackNavi.includes(activePage)) {
        return "black";
      }
    } else {
      const whiteMenu = [1, 3];
      const blackMenu = [2, 4];
      if (whiteMenu.includes(activePage)) {
        return "white";
      } else if (blackMenu.includes(activePage)) {
        return "black";
      }
    }
  };

  return (
    <div className={`header-container ${getBackground()}`}>
      <div className="header-inner">
        <div
          className={`header-logo ${getLogo()} cursor-pointer`}
          onClick={() => {
            if (isScrolling) return;
            navigate("/");
          }}
        />
        <IntroNavigation color={getNavi()} goToTop={goToTop} />
      </div>
    </div>
  );
};
