import "../../styles/inquiry/inquiry.css";
import useResponse from "../../hooks/useResponse";
import NewsFooter from "../../components/news/NewsFooter";
import { motion } from "framer-motion";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  return (
    <div className="inquiry-container">
      {!isMobile && <div className="inquiry-title-text font-bold">INQUIRY</div>}
      <div className="inquiry-content">
        <div className="inquiry-content-inner">
          <motion.div
            className="inquiry-title-container"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 0.5, delay: 0.25 }}
          >
            <div className="inquiry-title-inner1 font-bold">Easy and Fast</div>
            <div className="inquiry-title-inner2 font-light">쉽고 빠르게 ‘제휴 문의’</div>
          </motion.div>
          <motion.div
            className="inquiry-image"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.8 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          />
        </div>
      </div>
      <NewsFooter footerMode={"white"} textMode={"white"} active={1} move={move} />
    </div>
  );
};
