import "../../styles/menu/header.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onCloseNews } from "../../redux/slices/news";
import DetailNavigation from "./DetailNavigation";
import useResponse from "../../hooks/useResponse";
import NewsNavigation from "./NewsNavigation";
import useScrolling from "../../hooks/useScrolling";

export default () => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isScrolling } = useScrolling();

  return (
    <div className="detail-header-container">
      <div className="menu-header-inner">
        <div
          className="menu-header-logo cursor-pointer"
          onClick={() => {
            if (isScrolling) return;
            navigate("/");
            dispatch(onCloseNews(isDesktop));
          }}
        />
        {isDesktop ? <NewsNavigation /> : <DetailNavigation />}
      </div>
    </div>
  );
};
