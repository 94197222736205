import { useMediaQuery } from "react-responsive";
import { isMobile, isTablet, isDesktop, isMacOs, isWindows } from "react-device-detect";

export default () => {
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width:1024px)",
  });

  // const isDesktop =  isMacOs || isWindows;
  const isSmallDesktop = useMediaQuery({
    query: "(min-width:1024px) and (max-width:1499px)",
  });

  const isBigDesktop = useMediaQuery({
    query: "(min-width:1500px)",
  });

  return { isMobile, isTablet, isDesktop, isMacOs, isWindows, isSmallDesktop, isBigDesktop };
};
