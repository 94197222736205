import { isAndroid, isWindows } from "react-device-detect";

export const openStore = () => {
  if (isAndroid || isWindows) {
    window.open("https://play.google.com/store/apps/details?id=com.wing_chat&hl=ko-KR");
  } else {
    window.open("https://apps.apple.com/kr/app/%EC%9C%99%EC%B1%97/id6467384075");
  }
};

export const openAppleStore = () => {
  window.open("https://apps.apple.com/kr/app/%EC%9C%99%EC%B1%97/id6467384075");
};

export const openGoogleStore = () => {
  window.open("https://play.google.com/store/apps/details?id=com.wing_chat&hl=ko-KR");
};
