// import "../../styles/main/intro.css";
import { Link, scrollSpy } from "react-scroll";
import "../../styles/default/footer.css";
import { useMediaQuery } from "react-responsive";
import { useRef } from "react";
import { delay } from "../../utils/time";

type Props = {
  footerMode: "dark" | "white" | "trans";
  textMode: "dark" | "white" | "trans";
  active: number;
  move?: any;
};

export default ({ footerMode, textMode, active, move }: Props) => {
  const ref = useRef(null);

  const get = async () => {
    await delay(2000);
    get();
  };

  const getFooterColor = () => {
    if (footerMode === "dark") {
      return " footer-dark";
    } else if (footerMode === "white") {
      return " footer-white";
    } else {
      return "";
    }
  };

  const Navi = ({ id, number }: any) => {
    const naviClass = () => {
      if (textMode === "dark") {
        if (id === active) {
          return "footer-dark-active-number";
        } else {
          return "footer-dark-in-active-number";
        }
      } else if (textMode === "white") {
        if (id === active) {
          return "footer-white-active-number";
        } else {
          return "footer-white-in-active-number";
        }
      }
    };
    return (
      <span className={`footer-number ${naviClass()} font-bold cursor-pointer`}>{number}</span>
    );
  };

  return (
    <div className={"footer-container" + getFooterColor()}>
      <div className={"footer-inner"} ref={ref}>
        <Link
          activeClass="active1"
          to="section-1"
          onClick={() => {
            move && move(1);
          }}
          spy
          smooth
          duration={500}
        >
          <Navi id={1} number={"01"} />
        </Link>
        <Link
          activeClass="active2"
          to="section-2"
          onClick={() => {
            move && move(2);
          }}
          spy
          smooth
          duration={500}
        >
          <Navi id={2} number={"02"} />
        </Link>
        <Link
          activeClass="active3"
          to="section-3"
          onClick={() => {
            move && move(3);
          }}
          spy
          smooth
          duration={500}
        >
          <Navi id={3} number={"03"} />
        </Link>
        <Link
          activeClass="active4"
          to="section-4"
          onClick={() => {
            move && move(4);
          }}
          spy
          smooth
          duration={500}
        >
          <Navi id={4} number={"04"} />
        </Link>
        <Link
          activeClass="active5"
          to="section-5"
          onClick={() => {
            move && move(5);
          }}
          spy
          smooth
          duration={500}
        >
          <Navi id={5} number={"05"} />
        </Link>
      </div>
    </div>
  );
};
