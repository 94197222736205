import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Main from "../page/main/Main";
import Menu from "../page/menu/Menu";
import useResponse from "../hooks/useResponse";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onCloseMenu } from "../redux/slices/menu";

import NewsMain from "../page/news/NewsMain";
import InquiryMain from "../page/inquiry/InquiryMain";
import FaqMain from "../page/faq/FaqMain";
import IntroductionMain from "../page/introduction/IntroductionMain";
import { onCloseNews, onInActiveNews } from "../redux/slices/news";
import PolicyPage from "../page/docs/PolicyPage";
import TermsPage from "../page/docs/TermsPage";
import PrivacyPage from "../page/docs/PrivacyPage";
import Detail from "../page/news/Detail";

export default () => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const location = useLocation();

  const dispatch = useDispatch();

  const [goToTop, setGoToTop] = useState<() => void>(() => {});

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(onCloseMenu());
    dispatch(onCloseNews(isDesktop));

    // if(location.pathname)
    if (location.pathname != "/news" && !location.pathname.includes("detail")) {
      dispatch(onInActiveNews());
    }
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Main />} />

      <Route path="/introduction" element={<IntroductionMain />} />
      <Route path="/news" element={<NewsMain setGoToTop={setGoToTop} />} />
      <Route path="/inquiry" element={<InquiryMain />} />
      <Route path="/faq" element={<FaqMain />} />

      {/* <Route path="/detail" element={<Detail />} /> */}
      <Route path="/detail/:id" element={<Detail goToTop={goToTop} />} />

      <Route path="/policy" element={<PolicyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />

      {/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
      {!isDesktop && <Route path="/menu" element={<Menu />} />}
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
