import { motion, useAnimation } from "framer-motion";
import IntroductionFooter from "../../components/introduction/IntroductionFooter";
import "../../styles/introduction/app.css";
import { useEffect } from "react";
import useResponse from "../../hooks/useResponse";
import { Link } from "react-scroll";

type Props = {
  move?: (page: string | number) => void;
  silentMove?: (page: string | number) => void;
};

export default ({ move, silentMove }: Props) => {
  const { isMobile, isTablet, isDesktop, isSmallDesktop } = useResponse();

  const titleAnimation = useAnimation();

  const imageAnimation = useAnimation();
  const pointerAnimation = useAnimation();
  const fingerAnimation = useAnimation();

  useEffect(() => {
    startAnimation();
  }, []);

  const startAnimation = async () => {
    await titleAnimation.start({ opacity: 1 });
    await imageAnimation.start({ opacity: 1 });
    pointerAnimation.start({ opacity: 1 });
    await fingerAnimation.start({ opacity: 1 });
  };

  return (
    <div className="app-container">
      {!isMobile && <div className="app-title-text font-bold">APP</div>}

      <>
        <div className="app-container-black-box">
          <div className="app-container-inner">
            <motion.div
              className="app-title-container"
              initial={{ opacity: 0 }}
              animate={titleAnimation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.25, delay: 0.25 }}
            >
              <div className="app-title-inner1 font-bold">Easy and Fast ‘WINGCHAT’</div>
              <div className="app-title-inner2 font-light">쉽고 빠르게 ‘윙챗’</div>
            </motion.div>
            <div className="app-image-container cursor-pointer">
              <Link
                to="introduction-2"
                spy
                smooth
                duration={500}
                onClick={() => {
                  move && move(2);
                }}
              >
                <div className="app-image-container-inner">
                  <motion.div
                    className="app-image"
                    initial={{ opacity: 0 }}
                    animate={imageAnimation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.25 }}
                  />
                  <div className="app-image-icon-container">
                    <div className="app-image-icon-inner">
                      <div className="app-image-icon-pointer-container cursor-pointer">
                        <motion.div
                          className="app-image-pointer-inner"
                          initial={{ opacity: 0 }}
                          animate={pointerAnimation}
                          viewport={{ once: true, amount: 0.8 }}
                          transition={{ duration: 0.25 }}
                        >
                          <div className="app-image-icon-pointer" />
                        </motion.div>
                      </div>
                      <motion.div
                        className="app-image-icon-finger cursor-pointer"
                        initial={{ opacity: 0 }}
                        animate={fingerAnimation}
                        viewport={{ once: true, amount: 0.8 }}
                        transition={{ duration: 0.25 }}
                      />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <IntroductionFooter footerMode={"dark"} textMode={"dark"} active={1} move={move} />
      </>
    </div>
  );
};
