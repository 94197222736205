import "../../styles/menu/header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useResponse from "../../hooks/useResponse";
import { onCloseNews } from "../../redux/slices/news";
import { onOpenMenu } from "../../redux/slices/menu";
import { openStore } from "../../utils/open";

type Props = {
  color?: "white" | "black";
};

export default ({ color }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const dispatch = useDispatch();

  const location = useLocation();

  return (
    <>
      <div className="menu-right-container">
        <div className="menu-download cursor-pointer" onClick={openStore}>
          <span className="menu-download-text font-bold cursor-pointer">앱 다운로드</span>
        </div>
        {/* 닫기 버튼 */}
        <div
          className="mobile-menu font-bold cursor-pointer"
          onClick={() => {
            dispatch(onOpenMenu(location.pathname));
          }}
        >
          <div className={`mobile-menu-inner-long black-menu`} />
          <div className={`mobile-menu-inner-long black-menu`} />
          <div className={`mobile-menu-inner-short black-menu`} />
        </div>
      </div>
    </>
  );
};
