// /slices/user.ts

import { createSlice } from "@reduxjs/toolkit";

type Iinitial = {
  isScrolling: boolean;
};

const initialState: Iinitial = {
  isScrolling: false,
};

const scrollingSlice = createSlice({
  name: "scrolling",
  initialState,
  reducers: {
    onScrolling(state) {
      state.isScrolling = true;
    },

    onFinishScrolling(state) {
      state.isScrolling = false;
    },
  },
});

export const { onScrolling, onFinishScrolling } = scrollingSlice.actions;

export default scrollingSlice;
