import { useNavigate, useParams } from "react-router-dom";
import useResponse from "../../hooks/useResponse";
import { useDispatch } from "react-redux";
import "../../styles/news/detail.css";
import DetailHeader from "../../components/news/DetailHeader";
import { fDate } from "../../utils/time";
import { INews } from "../../@types/type";
import { Viewer } from "@toast-ui/react-editor";
import { useEffect, useState } from "react";
import { getNews } from "../../apis/services";
import { onActiveNews, onCloseNews } from "../../redux/slices/news";
import Menu from "../menu/Menu";
import useMenu from "../../hooks/useMenu";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  goToTop?: () => void;
};

export default ({ goToTop }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const { isScrolling } = useScrolling();
  // const { isOpenNews, newsDetail } = useNews();

  const [newsDetail, setNewsDetail] = useState<INews>();

  let { id } = useParams();

  const { isOpen } = useMenu();

  useEffect(() => {
    dispatch(onActiveNews());
  }, []);

  useEffect(() => {
    // window.history.pushState("", "", window.location.href);
    (async () => {
      const res: any = await getNews();

      res.map((news: INews) => {
        if (id && news.id == +id) {
          setNewsDetail(news);
        }
      });
    })();
  }, []);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const getType = (type: INews["type"]) => {
    if (type === "EVENT") {
      return "이벤트";
    }
    if (type === "NEWS") {
      return "보도자료";
    }

    return "보도자료";
  };

  return (
    <>
      <DetailHeader />

      {isOpen && !isDesktop && <Menu />}

      <div className="detail-container">
        {!isMobile && <div className="detail-title-text font-bold">ARCHIVE</div>}
        {newsDetail ? (
          <div className="detail-content">
            <div className="detail-content-inner">
              <div className="detail-create-date font-medium">
                {`${getType(newsDetail.type)} · ${fDate(newsDetail.createDate)}`}
              </div>

              <div className="detail-title font-light">{newsDetail.title}</div>

              <div
                className="detail-list-button cursor-pointer"
                onClick={() => {
                  if (isScrolling) return;
                  navigate(-1);

                  // dispatch(onCloseNews(isDesktop));
                }}
              >
                <div className="detail-list-button-text font-bold">목록으로</div>
              </div>

              <div className="detail-contents">
                <div className="detail-media-container">
                  {newsDetail.mediaUrlList.length > 0 &&
                    newsDetail.mediaUrlList.map((url: string) => {
                      return <img className="detail-media" src={`${url}`} />;
                    })}
                </div>
                <div className="detail-contents-text font-regular">
                  <Viewer usageStatistics={false} initialValue={newsDetail.contents} />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="detail-null-container font-regular">
            내용이 존재하지 않습니다.
            <br />
            3초 후 이전 페이지로 이동합니다.
          </div>
        )}
      </div>
    </>
  );
};
