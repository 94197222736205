import ReactFullpage from "@fullpage/react-fullpage";
import Intro from "./Intro";
import Messenger from "./Messenger";
import Payment from "./Payment";
import Wingchat from "./Wingchat";
import Withus from "./Withus";
import useResponse from "../../hooks/useResponse";
import { useEffect, useRef, useState } from "react";
import { Element } from "react-scroll";
import MainHeader from "../../components/main/MainHeader";
import Menu from "../menu/Menu";
import useMenu from "../../hooks/useMenu";
import { delay } from "../../utils/time";
import { onFinishScrolling, onScrolling } from "../../redux/slices/scrolling";
import useScrolling from "../../hooks/useScrolling";
import { useDispatch } from "react-redux";

export default () => {
  const { isMobile, isTablet, isDesktop, isWindows, isMacOs } = useResponse();

  const mainPageRef = useRef<any>(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [activePage, setActivePage] = useState(0);

  const dispatch = useDispatch();

  const { isOpen } = useMenu();

  useEffect(() => {
    (async () => {
      if (activePage != 0) {
        dispatch(onScrolling());
        await delay(700);
        dispatch(onFinishScrolling());
      }
    })();
  }, [activePage]);

  useEffect(() => {
    if (mainPageRef.current) {
      const currentPageClass: string =
        mainPageRef.current.fullpageApi.getActiveSection().item.className;

      if (currentPageClass.includes("section-1")) {
        setActivePage(1);
      } else if (currentPageClass.includes("section-2")) {
        setActivePage(2);
      } else if (currentPageClass.includes("section-3")) {
        setActivePage(3);
      } else if (currentPageClass.includes("section-4")) {
        setActivePage(4);
      } else if (currentPageClass.includes("section-5")) {
        setActivePage(5);
      }
    }
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (isDesktop) {
        await delay(1);
        mainPageRef.current.fullpageApi.silentMoveTo("section-1");
      }
    })();
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // 스크롤 이벤트 리스너를 등록합니다.
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 각 페이지의 위치를 계산하고 현재 페이지를 결정합니다.
      let newCurrentPage: number = 0;
      pageRefs.current.forEach((pageRef, index) => {
        if (pageRef) {
          const pageTop = pageRef.offsetTop;
          const pageBottom = pageTop + pageRef.clientHeight;
          if (scrollY >= pageTop && scrollY < pageBottom) {
            newCurrentPage = index + 1;
          }
        }
      });

      if (newCurrentPage == currentPage) {
        return;
      }

      // 현재 페이지를 업데이트합니다.
      setCurrentPage(newCurrentPage);
    };

    // 컴포넌트가 마운트될 때 스크롤 이벤트 리스너를 등록하고,
    // 언마운트될 때 이벤트 리스너를 제거합니다.
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTop = () => {
    if (isDesktop) {
      mainPageRef.current.fullpageApi.moveTo(1);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const tabletOrMobile = [
    { name: "section-1", component: Intro },
    { name: "section-2", component: Messenger },
    { name: "section-3", component: Payment },
    { name: "section-4", component: Withus },
    { name: "section-5", component: Wingchat },
  ];

  return (
    <>
      <MainHeader activePage={isDesktop ? activePage : currentPage} goToTop={goToTop} />

      {isOpen && !isDesktop && <Menu />}

      {isDesktop && (
        <ReactFullpage
          lockAnchors
          ref={mainPageRef}
          licenseKey={"61BKK-9MOZI-KAY66-I41JK-UJFNN"}
          credits={{ enabled: false }}
          scrollingSpeed={700} /* Options here */
          anchors={["section-1", "section-2", "section-3", "section-4", "section-5"]}
          onLeave={() => {
            setRefresh(!refresh);
          }}
          scrollBar={false}
          render={({ state, fullpageApi }) => {
            const move = (page: string | number) => {
              fullpageApi.moveTo(page);
            };
            return (
              <ReactFullpage.Wrapper>
                <div className="section section-1">
                  <Intro move={move} />
                </div>

                <div className="section section-2">
                  <Messenger move={move} />
                </div>

                <div className="section section-3">
                  <Payment move={move} isActive={activePage === 3} />
                </div>

                <div className="section section-4">
                  <Withus move={move} />
                </div>

                <div className="section section-5">
                  <Wingchat move={move} />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      )}

      {!isDesktop && (
        <div>
          {tabletOrMobile.map((page, index) => {
            return (
              <div key={page.name} ref={(el: any) => (pageRefs.current[index] = el)}>
                <Element className="section" name={page.name}>
                  <page.component />
                </Element>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
