import { getNewsList, getPolicyString } from "./apis";

export const getNews = async () => {
  const res: any = await getNewsList();

  return res.data.list;
};

export const getPolicy = async (
  title: "운영정책" | "전자상거래 표준약관" | "개인정보 처리방침"
) => {
  try {
    const res: any = await getPolicyString(title);
    return res.data.data;
  } catch (err) {}
};
