import { useEffect, useState } from "react";
import { getPolicy } from "../../apis/services";
import { Viewer } from "@toast-ui/react-editor";

export default () => {
  const [policyContents, setPolicyContents] = useState<any>();

  useEffect(() => {
    (async () => {
      const res: any = await getPolicy("운영정책");
      setPolicyContents(res);
    })();
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto", padding: 15 }}>
      {policyContents && <Viewer usageStatistics={false} initialValue={policyContents.contents} />}
    </div>
  );
};
