// /slices/user.ts

import { createSlice } from "@reduxjs/toolkit";

type Iinitial = {
  isOpen: boolean;
  prevPage: "introduction" | "news" | "inquiry" | "faq" | undefined;
};

const initialState: Iinitial = {
  isOpen: false,
  prevPage: undefined,
};

const menuSlice = createSlice({
  // slice의 이름
  name: "menu",
  // slice의 기본 값
  initialState,
  // redux state의 값을 변경시키는 함수들을 선언
  // 이처럼 state를 변경시키는 함수를 action이라고 한다.

  // 그러한 action을 컴포넌트에서 실행시키기 위해서는 dispatch가
  // 필요하다.
  reducers: {
    // useState의 setState 함수와 같은 기능을 하는 함수를 미리 만들어둠
    // 아래의 함수처럼 코드를 작성하면, 원하는 키의 값을 변경할 수 있다.
    // action에 있는 값을 사용하기 위해서는 action.payload 라는 형식으로
    // 사용해야한다.
    onOpenMenu(state, action) {
      state.isOpen = true;
      // document.body.style.overflowY = "hidden";

      if (action.payload !== undefined) {
        const newPrevPage = action.payload.replace("/", "");
        state.prevPage = newPrevPage;
      }
    },
    // state를 빈 값으로 변경시키는 함수
    onCloseMenu(state) {
      state.isOpen = false;
      state.prevPage = undefined;
      // document.body.style.overflowY = "auto";
    },
  },
  // 여기에는 비동기 action을 선언한다
  extraReducers: (builder) => {},
});

// 위에서 만든 setUser 함수와 clearUser 함수를 export
export const { onOpenMenu, onCloseMenu } = menuSlice.actions;

export default menuSlice;
