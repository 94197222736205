import { Navigate, useNavigate } from "react-router-dom";
import "../../styles/default/header.css";
import Navigation from "../default/Navigation";
import NewsNavigation from "./NewsNavigation";
import { useEffect, useState } from "react";
import useResponse from "../../hooks/useResponse";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  activePage: number;
  goToTop: () => void;
};

export default ({ activePage, goToTop }: Props) => {
  const navigate = useNavigate();

  const { isMobile, isTablet, isDesktop } = useResponse();

  const [logoClass, setLogoClass] = useState("");

  const { isScrolling } = useScrolling();

  useEffect(() => {
    if (isDesktop) {
      setLogoClass("black-header-logo");
    } else {
      setLogoClass("phone-black-header-logo");
    }
  }, [isMobile, isTablet, isDesktop]);

  return (
    <div className={`header-container white-bg`}>
      <div className="header-inner">
        <div
          className={`header-logo ${logoClass} cursor-pointer`}
          onClick={() => {
            if (isScrolling) return;
            navigate("/");
          }}
        />
        <NewsNavigation color={"white"} goToTop={goToTop} />
      </div>
    </div>
  );
};
