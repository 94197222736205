import React, { useEffect, useState } from "react";
import { getPolicy } from "../../apis/services";
import { Viewer } from "@toast-ui/react-editor";

// import PolicyPage from "";

export default () => {
  const [privacyContents, setPrivacyContents] = useState<any>();

  useEffect(() => {
    (async () => {
      const res: any = await getPolicy("개인정보 처리방침");
      setPrivacyContents(res);
    })();
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto", padding: 15 }}>
      {privacyContents && (
        <Viewer usageStatistics={false} initialValue={privacyContents.contents} />
      )}
    </div>
  );
};
