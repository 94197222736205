import ReactFullpage from "@fullpage/react-fullpage";

import useResponse from "../../hooks/useResponse";
import { useEffect, useRef, useState } from "react";
import { Element } from "react-scroll";
import Menu from "../menu/Menu";
import useMenu from "../../hooks/useMenu";
import Wingchat from "../main/Wingchat";
import News from "./News";
import Archive from "./Archive";
import NewsHeader from "../../components/news/NewsHeader";
import Detail from "./Detail";
import useNews from "../../hooks/useNews";
import { useDispatch } from "react-redux";
import { onCloseNews, onInActiveNews } from "../../redux/slices/news";
import { delay } from "../../utils/time";
import { onFinishScrolling, onScrolling } from "../../redux/slices/scrolling";

type Props = {
  setGoToTop: React.Dispatch<React.SetStateAction<() => void>>;
};

export default ({ setGoToTop }: Props) => {
  const { isMobile, isTablet, isDesktop, isWindows, isMacOs } = useResponse();

  const mainPageRef = useRef<any>(null);

  const [refresh, setRefresh] = useState<boolean>(false);

  const [activePage, setActivePage] = useState(0);

  const { isOpen } = useMenu();

  const { isOpenNews, newsDetail } = useNews();

  const dispatch = useDispatch();

  const newsSecond = document.getElementsByName("news-2");

  const [currentPage, setCurrentPage] = useState<number>(0);
  const pageRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (mainPageRef.current) {
      const currentPageClass: string =
        mainPageRef.current.fullpageApi.getActiveSection().item.className;

      if (currentPageClass.includes("news-1")) {
        setActivePage(1);
      } else if (currentPageClass.includes("news-2")) {
        setActivePage(2);
      } else if (currentPageClass.includes("news-3")) {
        setActivePage(3);
      }
    }
  }, [refresh]);

  useEffect(() => {
    (async () => {
      if (isDesktop) {
        await delay(1);
        mainPageRef.current.fullpageApi.silentMoveTo("news-1");
      }
    })();
  }, []);

  const goToSecond = async () => {
    if (isDesktop) {
      await delay(10);
      mainPageRef.current.fullpageApi.silentMoveTo("news-2");
      dispatch(onInActiveNews());
    }
  };

  // useEffect(() => {
  //   if (isDesktop) {
  //     if (mainPageRef.current && mainPageRef.current.fullpageApi) {
  //       if (!isOpenNews) {
  //         mainPageRef.current.fullpageApi.silentMoveTo("news-2");
  //       }
  //     }
  //   } else {
  //     if (isOpenNews) {
  //       document.body.style.overflowY = "hidden";
  //     } else {
  //       document.body.style.overflowY = "auto";
  //     }
  //   }
  // }, [isOpenNews]);
  useEffect(() => {
    (async () => {
      if (activePage != 0) {
        dispatch(onScrolling());
        await delay(700);
        dispatch(onFinishScrolling());
      }
    })();
  }, [activePage]);

  useEffect(() => {
    // 스크롤 이벤트 리스너를 등록합니다.
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 각 페이지의 위치를 계산하고 현재 페이지를 결정합니다.
      let newCurrentPage: number = 0;
      pageRefs.current.forEach((pageRef, index) => {
        if (pageRef) {
          const pageTop = pageRef.offsetTop;
          const pageBottom = pageTop + pageRef.clientHeight;
          if (scrollY >= pageTop && scrollY < pageBottom) {
            newCurrentPage = index + 1;
          }
        }
      });

      if (newCurrentPage == currentPage) {
        return;
      }

      // 현재 페이지를 업데이트합니다.
      setCurrentPage(newCurrentPage);
    };

    // 컴포넌트가 마운트될 때 스크롤 이벤트 리스너를 등록하고,
    // 언마운트될 때 이벤트 리스너를 제거합니다.
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const tabletOrMobile = [
    { name: "news-1", component: News },
    { name: "news-2", component: Archive },
    { name: "news-3", component: Wingchat },
  ];

  const goToTop = () => {
    const goToTopFunc = () => {
      if (isDesktop) {
        mainPageRef.current.fullpageApi.moveTo(1);
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    };

    setGoToTop(goToTopFunc);

    goToTopFunc();
  };

  return (
    <>
      <NewsHeader activePage={isDesktop ? activePage : currentPage} goToTop={goToTop} />

      {isOpenNews && <Detail />}

      {isOpen && !isDesktop && <Menu goToTop={goToTop} />}

      {isDesktop && (
        <ReactFullpage
          lockAnchors
          ref={mainPageRef}
          licenseKey={"61BKK-9MOZI-KAY66-I41JK-UJFNN"}
          credits={{ enabled: false }}
          scrollingSpeed={700}
          anchors={["news-1", "news-2", "news-3"]}
          onLeave={() => {
            setRefresh(!refresh);
          }}
          scrollBar={false}
          render={({ state, fullpageApi }) => {
            const move = (page: string | number) => {
              fullpageApi.moveTo(page);
            };

            return (
              <ReactFullpage.Wrapper>
                <div className="section news-1">
                  <News move={move} />
                </div>

                <div className="section news-2">
                  <Archive move={move} setSection={goToSecond} />
                </div>

                <div className="section news-3">
                  <Wingchat move={move} />
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      )}

      {!isDesktop && (
        <div>
          {tabletOrMobile.map((page, index) => {
            return (
              <div key={page.name} id={page.name} ref={(el: any) => (pageRefs.current[index] = el)}>
                <Element className="section" name={page.name}>
                  <page.component />
                </Element>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
