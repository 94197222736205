import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import { Provider } from "react-redux";
import store from "../src/redux/store";
import Routing from "./routes/Routing";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  const persistor = persistStore(store);

  return (
    <div className="App">
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <Routing />
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    </div>
  );
}

export default App;
