import { Navigate, useNavigate } from "react-router-dom";
import useResponse from "../../hooks/useResponse";
import "../../styles/default/header.css";
import Navigation from "../default/Navigation";

type Props = {
  activePage: number;
  goToTop: () => void;
};

export default ({ activePage, goToTop }: Props) => {
  const navigate = useNavigate();
  const { isTablet, isDesktop } = useResponse();

  const whiteLogo = [1, 3, 4];
  const blackLogo = [2, 5];

  const whiteNavi = [3, 4];
  const blackNavi = [1, 2, 5];

  const whiteBg = [2, 5];
  const blackBg = [3];
  const transBg = [1, 4];

  const getLogo = () => {
    if (isDesktop) {
      if (whiteLogo.includes(activePage)) {
        return "white-header-logo";
      } else if (blackLogo.includes(activePage)) {
        return "black-header-logo";
      } else {
        return "white-header-logo";
      }
    } else {
      if (whiteLogo.includes(activePage)) {
        return "phone-white-header-logo";
      } else if (blackLogo.includes(activePage)) {
        return "phone-black-header-logo";
      } else {
        return "phone-white-header-logo";
      }
    }
  };

  const getBackground = () => {
    if (isDesktop) {
      return "trans-bg";
    }

    if (transBg.includes(activePage)) {
      return "trans-bg";
    } else if (whiteBg.includes(activePage)) {
      return "white-bg";
    } else if (blackBg.includes(activePage)) {
      return "black-bg";
    } else {
      return "trans-bg";
    }
  };

  const getNavi = () => {
    if (isDesktop) {
      if (whiteNavi.includes(activePage)) {
        return "white";
      } else if (blackNavi.includes(activePage)) {
        return "black";
      }
    } else {
      const whiteMenu = [1, 3, 4];
      const blackMenu = [2, 5];

      if (isTablet && activePage === 1) {
        return "black";
      }

      if (whiteMenu.includes(activePage)) {
        return "white";
      } else if (blackMenu.includes(activePage)) {
        return "black";
      } else {
        if (isTablet) {
          return "black";
        } else {
          return "white";
        }
      }
    }
  };

  return (
    <div className={`header-container ${getBackground()}`}>
      <div className="header-inner">
        <div
          className={`header-logo ${getLogo()} cursor-pointer`}
          onClick={() => {
            // navigate("/");
            goToTop();
          }}
        />
        <Navigation color={getNavi()} />
      </div>
    </div>
  );
};
