// import "../../styles/main/intro.css";
import { Link, scrollSpy } from "react-scroll";
import "../../styles/introduction/introfuctionFooter.css";
import { useRef } from "react";
import { delay } from "../../utils/time";

type Props = {
  footerMode: "dark" | "white" | "trans";
  textMode: "dark" | "white" | "trans";
  active: number;
  move?: any;
};

export default ({ footerMode, textMode, active, move }: Props) => {
  const ref = useRef(null);

  const get = async () => {
    await delay(2000);
    get();
  };

  const getFooterColor = () => {
    if (footerMode === "dark") {
      return " introduction-footer-dark";
    } else if (footerMode === "white") {
      return " introduction-footer-white";
    } else {
      return "";
    }
  };

  const Navi = ({ id, number }: any) => {
    const naviClass = () => {
      if (textMode === "dark") {
        if (id === active) {
          return "introduction-footer-dark-active-number";
        } else {
          return "introduction-footer-dark-in-active-number";
        }
      } else if (textMode === "white") {
        if (id === active) {
          return "introduction-footer-white-active-number";
        } else {
          return "introduction-footer-white-in-active-number";
        }
      }
    };
    return (
      <span className={`introduction-footer-number ${naviClass()} font-bold cursor-pointer`}>
        {number}
      </span>
    );
  };

  return (
    <div className={"introduction-footer-container" + getFooterColor()}>
      <div className={"introduction-footer-inner"} ref={ref}>
        <Link
          activeClass="active1"
          to="introduction-1"
          spy
          smooth
          duration={500}
          onClick={() => {
            move && move(1);
          }}
        >
          <Navi id={1} number={"01"} />
        </Link>
        <Link
          activeClass="active2"
          to="introduction-2"
          spy
          smooth
          duration={500}
          onClick={() => {
            move && move(2);
          }}
        >
          <Navi id={2} number={"02"} />
        </Link>
        <Link
          activeClass="active3"
          to="introduction-3"
          spy
          smooth
          duration={500}
          onClick={() => {
            move && move(3);
          }}
        >
          <Navi id={3} number={"03"} />
        </Link>
        <Link
          activeClass="active4"
          to="introduction-4"
          spy
          smooth
          duration={500}
          onClick={() => {
            move && move(4);
          }}
        >
          <Navi id={4} number={"04"} />
        </Link>
      </div>
    </div>
  );
};
