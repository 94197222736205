import { AnimationControls, Variants, motion, useAnimation } from "framer-motion";
import IntroductionFooter from "../../components/introduction/IntroductionFooter";
import useResponse from "../../hooks/useResponse";
import "../../styles/introduction/description.css";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { delay } from "../../utils/time";

import phoneAnimation1 from "../../assets/introduction/description/phoneAnimation/phoneAnimation1.png";

import phoneAnimation2 from "../../assets/introduction/description/phoneAnimation/phoneAnimation2.png";

import phoneAnimation3 from "../../assets/introduction/description/phoneAnimation/phoneAnimation3.png";

import phoneAnimation4 from "../../assets/introduction/description/phoneAnimation/phoneAnimation4.png";

import phone1 from "../../assets/introduction/description/mobile/phone1.png";

import phone2 from "../../assets/introduction/description/mobile/phone2.png";

import phone3 from "../../assets/introduction/description/mobile/phone3.png";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const [isStartPhone1Animation, setIsStartPhone1Animation] = useState<boolean>(false);

  const [isStartPhone2Animation, setIsStartPhone2Animation] = useState<boolean>(false);

  const [isStartPhone3Animation, setIsStartPhone3Animation] = useState<boolean>(false);

  const [isStartPhone4Animation, setIsStartPhone4Animation] = useState<boolean>(false);

  const [desktopRef, inViewDesktop] = useInView();

  const container1Animation = useAnimation();
  const container2Animation = useAnimation();
  const container3Animation = useAnimation();

  const inner1Animation = useAnimation();
  const inner2Animation = useAnimation();
  const inner3Animation = useAnimation();

  const title1Animation = useAnimation();
  const title2Animation = useAnimation();
  const title3Animation = useAnimation();

  const startAnimation = async () => {
    await container1Animation.start({ opacity: 1 });
    await inner1Animation.start({ opacity: 1 });
    await title1Animation.start({ opacity: 1 });

    await container2Animation.start({ opacity: 1 });
    await inner2Animation.start({ opacity: 1 });
    await title2Animation.start({ opacity: 1 });

    await container3Animation.start({ opacity: 1 });
    await inner3Animation.start({ opacity: 1 });
    await title3Animation.start({ opacity: 1 });
  };

  const startPhone1 = async () => {
    // 친구
    if (isStartPhone1Animation) return;

    const animate = inner1Animation;

    setIsStartPhone1Animation(true);

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phoneAnimation1})`,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phone1})`,
    });

    setIsStartPhone1Animation(false);
  };

  const startPhone2 = async () => {
    // 채팅

    if (isStartPhone2Animation) return;

    const animate = inner2Animation;

    setIsStartPhone2Animation(true);

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phoneAnimation2})`,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }
    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phone2})`,
    });

    setIsStartPhone2Animation(false);
  };

  const startPhone3 = async () => {
    // 페이
    if (isStartPhone3Animation) return;

    const animate = inner3Animation;

    setIsStartPhone3Animation(true);

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phoneAnimation4})`,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phone3})`,
    });

    setIsStartPhone3Animation(false);
  };

  const startPhone4 = async () => {
    // 페이
    if (isStartPhone4Animation) return;

    const animate = inner3Animation;

    setIsStartPhone4Animation(true);

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phoneAnimation3})`,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 0,
    });

    if (isDesktop) {
      await delay(500);
    } else {
      await delay(100);
    }

    await animate.start({
      opacity: 1,
      backgroundImage: `url(${phone3})`,
    });
    setIsStartPhone4Animation(false);
  };

  useEffect(() => {
    if (isDesktop && inViewDesktop) {
      startAnimation();
    }
  }, [inViewDesktop]);

  const titleVariants: Variants = {
    offscreen: {
      x: -100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "keyframes",
        duration: 0.5,
      },
    },
  };

  const titleRightVariants: Variants = {
    offscreen: {
      x: 100,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "keyframes",
        duration: 0.5,
      },
    },
  };

  return (
    <div className={`${!isDesktop ? "description-container" : "description-d-container"}`}>
      {!isMobile && <div className="description-title-text font-bold">DESCRIPTION</div>}

      {isMobile && (
        <div className="description-box">
          <div className="description-content-container">
            <div className="description-content-inner">
              <motion.div
                className="description-title1-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="description-title1-inner1 font-light">친구</div>
                  <div className="description-title1-inner2 font-medium">
                    아래로 스크롤 하지 않아도 많은 친구를
                    {isMobile && <br />}한 눈에 볼 수 있는 리스트 형태로,
                    {isMobile && <br />}
                    {isMobile && <br />}
                    친구 상세보기를 하지 않고도
                    {isMobile && <br />}
                    1초 송금, 1초 결제 가능합니다.
                  </div>
                </motion.div>
              </motion.div>

              <div className="description-phone-area description-phone-area-left">
                <motion.div
                  className="description-phone-container"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1 }}
                >
                  <motion.div
                    className="description-phone-content description-phone1"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    animate={inner1Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    {!isStartPhone1Animation && (
                      <div onClick={startPhone1} className="cursor-pointer">
                        <div className="description-pointer description-pointer1 cursor-pointer">
                          <div className="description-pointer-inner" />
                        </div>
                        <div className="description-finger description-finger1 description-top-finger cursor-pointer" />
                        <div className="touch-box-1" />
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              </div>

              <motion.div
                className="description-title2-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="description-title2-inner1 font-light">채팅</div>
                  <div className="description-title2-inner2 font-medium">
                    아래로 스크롤 하지 않아도 많은 채팅방을
                    {isMobile && <br />}한 눈에 볼 수 있는 리스트 형태로,
                    {isMobile && <br />}
                    {isMobile && <br />}
                    명확한 알림 뱃지 UI를 사용하여{isMobile && <br />}
                    사용자의 실수를 줄여줍니다.
                  </div>
                </motion.div>
              </motion.div>

              <div className="description-phone-area description-phone-area-right">
                <motion.div
                  className="description-phone-container"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1 }}
                >
                  <motion.div
                    className="description-phone-content description-phone2"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    animate={inner2Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    {!isStartPhone2Animation && (
                      <div onClick={startPhone2} className="cursor-pointer">
                        <div className="description-pointer description-pointer2 cursor-pointer">
                          <div className="description-pointer-inner" />
                        </div>
                        <div className="description-finger description-finger2 description-top-finger cursor-pointer" />
                        <div className="touch-box-2" />
                      </div>
                    )}
                  </motion.div>
                </motion.div>
              </div>

              <motion.div
                className="description-title3-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="description-title3-inner1 font-light">페이</div>
                  <div className="description-title3-inner2 font-medium">
                    한 눈에 보이는 결제기록을 통해
                    {isMobile && <br />}
                    보다 빠르고 쉽게 나의 금융을
                    {isMobile && <br />}
                    관리 할 수 있습니다.
                  </div>
                </motion.div>
              </motion.div>

              <div className="description-phone-area description-phone-area-left">
                <motion.div
                  className="description-phone-container"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1 }}
                >
                  <motion.div
                    className="description-phone-content description-phone3"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    animate={inner3Animation}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 1, delay: 0.5 }}
                  >
                    {!isStartPhone3Animation && !isStartPhone4Animation && (
                      <>
                        <div onClick={startPhone3} className="cursor-pointer">
                          <div className="description-pointer description-pointer3 cursor-pointer">
                            <div className="description-pointer-inner" />
                          </div>
                          <div className="description-finger description-finger3 description-bottom-finger cursor-pointer" />
                          <div className="touch-box-3" />
                        </div>

                        <div onClick={startPhone4} className="cursor-pointer">
                          <div className="description-pointer description-pointer4 cursor-pointer">
                            <div className="description-pointer-inner" />
                          </div>
                          <div className="description-finger description-finger4 description-top-finger cursor-pointer" />
                          <div className="touch-box-4" />
                        </div>
                      </>
                    )}
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isTablet && (
        <div className="description-box">
          <div className="description-content-container">
            <div className="description-content-inner">
              <motion.div
                className="description-t-phone-area description-t-phone-area1"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1 }}
              >
                <motion.div
                  className="description-phone-content description-phone1"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  animate={inner1Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  {!isStartPhone1Animation && (
                    <div onClick={startPhone1} className="cursor-pointer">
                      <div className="description-pointer description-pointer1 cursor-pointer">
                        <div className="description-pointer-inner" />
                      </div>
                      <div className="description-finger description-finger1 description-top-finger cursor-pointer" />
                      <div className="touch-box-1" />
                    </div>
                  )}
                </motion.div>
              </motion.div>

              <motion.div
                className="description-t-title1-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="description-t-title1-inner1 font-light">친구</div>
                  <div className="description-t-title1-inner2 font-medium">
                    아래로 스크롤 하지 않아도 많은 친구를
                    <br />한 눈에 볼 수 있는 리스트 형태로,
                    <br />
                    <br />
                    친구 상세보기를 하지 않고도
                    <br />
                    1초 송금, 1초 결제 가능합니다.
                  </div>
                </motion.div>
              </motion.div>

              <motion.div
                className="description-t-phone-area description-t-phone-area2"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1 }}
              >
                <motion.div
                  className="description-phone-content description-phone2"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  animate={inner2Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  {!isStartPhone2Animation && (
                    <div onClick={startPhone2} className="cursor-pointer">
                      <div className="description-pointer description-pointer2 cursor-pointer">
                        <div className="description-pointer-inner" />
                      </div>
                      <div className="description-finger description-finger2 description-top-finger cursor-pointer" />
                      <div className="touch-box-2" />
                    </div>
                  )}
                </motion.div>
              </motion.div>

              <motion.div
                className="description-t-title2-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleRightVariants}>
                  <div className="description-t-title2-inner1 font-light">채팅</div>
                  <div className="description-t-title2-inner2 font-medium">
                    아래로 스크롤 하지 않아도 많은 채팅방을
                    <br />
                    한 눈에 볼 수 있는 리스트 형태로,
                    <br />
                    <br />
                    명확한 알림 뱃지 UI를 사용하여
                    <br />
                    사용자의 실수를 줄여줍니다.
                  </div>
                </motion.div>
              </motion.div>

              <motion.div
                className="description-t-phone-area description-t-phone-area3"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 1 }}
              >
                <motion.div
                  className="description-phone-content description-phone3"
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  animate={inner3Animation}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ duration: 1, delay: 0.5 }}
                >
                  {!isStartPhone3Animation && !isStartPhone4Animation && (
                    <>
                      <div onClick={startPhone3} className="cursor-pointer">
                        <div className="description-pointer description-pointer3 cursor-pointer">
                          <div className="description-pointer-inner" />
                        </div>
                        <div className="description-finger description-finger3 description-bottom-finger cursor-pointer" />
                        <div className="touch-box-3" />
                      </div>

                      <div onClick={startPhone4} className="cursor-pointer">
                        <div className="description-pointer description-pointer4 cursor-pointer">
                          <div className="description-pointer-inner" />
                        </div>
                        <div className="description-finger description-finger4 description-top-finger cursor-pointer" />
                        <div className="touch-box-4" />
                      </div>
                    </>
                  )}
                </motion.div>
              </motion.div>

              <motion.div
                className="description-t-title3-container"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}
              >
                <motion.div variants={titleVariants}>
                  <div className="description-t-title3-inner1 font-light">페이</div>
                  <div className="description-t-title3-inner2 font-medium">
                    한 눈에 보이는 결제기록을 통해
                    <br />
                    보다 빠르고 쉽게 나의 금융을
                    <br />
                    관리 할 수 있습니다.
                  </div>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
      )}

      {isDesktop && (
        <div className="description-d-content-box">
          <div className="description-d-content-container" ref={desktopRef}>
            <motion.div
              className="description-d-phone description-d-phone1"
              initial={{ opacity: 0 }}
              animate={container1Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.4 }}
            >
              <motion.div
                className="description-d-phone-inner description-d-phone-inner1"
                initial={{ opacity: 0 }}
                animate={inner1Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.4 }}
              >
                {!isStartPhone1Animation && (
                  <div onClick={startPhone1} className="cursor-pointer">
                    <div className="description-pointer description-pointer1 cursor-pointer">
                      <div className="description-pointer-inner" />
                    </div>
                    <div className="description-finger description-finger1 description-top-finger cursor-pointer" />
                    <div className="touch-box-1" />
                  </div>
                )}
              </motion.div>
            </motion.div>

            <motion.div
              className="description-d-title1-container"
              initial={{ opacity: 0 }}
              animate={title1Animation}
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="description-d-title1-inner1 font-light">친구</div>

              <div className="description-d-title1-inner2 font-medium">
                아래로 스크롤 하지 않아도 많은 친구를
                <br />
                한 눈에 볼 수 있는 리스트 형태로,
                <br />
                <br />
                친구 상세보기를 하지 않고도
                <br />
                1초 송금, 1초 결제 가능합니다.
              </div>
            </motion.div>

            <motion.div
              className="description-d-phone description-d-phone2"
              initial={{ opacity: 0 }}
              animate={container2Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.4 }}
            >
              <motion.div
                className="description-d-phone-inner description-d-phone-inner2"
                initial={{ opacity: 0 }}
                animate={inner2Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.4 }}
              >
                {!isStartPhone2Animation && (
                  <div onClick={startPhone2} className="cursor-pointer">
                    <div className="description-pointer description-pointer2 cursor-pointer">
                      <div className="description-pointer-inner" />
                    </div>
                    <div className="description-finger description-finger2 description-top-finger cursor-pointer" />
                    <div className="touch-box-2" />
                  </div>
                )}
              </motion.div>
            </motion.div>

            <motion.div
              className="description-d-title2-container"
              initial={{ opacity: 0 }}
              animate={title2Animation}
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="description-d-title2-inner1 font-light">채팅</div>

              <div className="description-d-title2-inner2 font-medium">
                아래로 스크롤 하지 않아도 많은 채팅방을
                <br />
                한 눈에 볼 수 있는 리스트 형태로,
                <br />
                <br />
                명확한 알림 뱃지 UI를 사용하여
                <br />
                사용자의 실수를 줄여줍니다.
              </div>
            </motion.div>

            <motion.div
              className="description-d-phone description-d-phone3"
              initial={{ opacity: 0 }}
              animate={container3Animation}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ duration: 0.4 }}
            >
              <motion.div
                className="description-d-phone-inner description-d-phone-inner3"
                initial={{ opacity: 0 }}
                animate={inner3Animation}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ duration: 0.4 }}
              >
                {!isStartPhone3Animation && !isStartPhone4Animation && (
                  <>
                    <div onClick={startPhone3} className="cursor-pointer">
                      <div className="description-pointer description-pointer3 cursor-pointer">
                        <div className="description-pointer-inner" />
                      </div>
                      <div className="description-finger description-finger3 description-bottom-finger cursor-pointer" />
                      <div className="touch-box-3" />
                    </div>

                    <div onClick={startPhone4} className="cursor-pointer">
                      <div className="description-pointer description-pointer4 cursor-pointer">
                        <div className="description-pointer-inner" />
                      </div>
                      <div className="description-finger description-finger4 description-top-finger cursor-pointer" />
                      <div className="touch-box-4" />
                    </div>
                  </>
                )}
              </motion.div>
            </motion.div>

            <motion.div
              className="description-d-title3-container"
              initial={{ opacity: 0 }}
              animate={title3Animation}
              viewport={{ once: true, amount: 0.8 }}
            >
              <div className="description-d-title3-inner1 font-light">페이</div>

              <div className="description-d-title3-inner2 font-medium">
                한 눈에 보이는 결제기록을 통해
                <br />
                보다 빠르고 쉽게 나의 금융을
                <br />
                관리 할 수 있습니다.
              </div>
            </motion.div>
          </div>
        </div>
      )}

      <IntroductionFooter footerMode={"white"} textMode={"white"} active={2} move={move} />
    </div>
  );
};
