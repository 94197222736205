import { useNavigate } from "react-router-dom";
import MenuHeader from "../../components/memu/mobile/MenuHeader";
import useMenu from "../../hooks/useMenu";
import useResponse from "../../hooks/useResponse";
import { useDispatch } from "react-redux";
import "../../styles/menu/menu.css";
import { onCloseMenu } from "../../redux/slices/menu";
import { onCloseNews } from "../../redux/slices/news";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  goToTop?: () => void;
};

export default ({ goToTop }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const { isOpen, prevPage } = useMenu();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isScrolling } = useScrolling();

  return (
    <>
      {!isDesktop && (
        <>
          <MenuHeader />
          <div className="menu-container">
            <div className="menu-content">
              <div className="menu-title-container">
                <div className="menu-title-inner1 font-medium">Easy and Fast ‘WINGCHAT’</div>
                <div className="menu-title-inner2 font-light">쉽고 빠르게 ‘윙챗’</div>
              </div>

              <div className="menu-navi-container">
                <div
                  className={`menu-navi-inner font-bold ${
                    prevPage === "introduction" && "menu-navi-active"
                  } cursor-pointer`}
                  onClick={() => {
                    if (prevPage === "introduction" && goToTop) {
                      dispatch(onCloseMenu());
                      goToTop();
                    } else {
                      if (isScrolling) return;
                      navigate("/introduction");
                      dispatch(onCloseMenu());
                      dispatch(onCloseNews(isDesktop));
                    }
                  }}
                >
                  윙챗 소개
                </div>
                <div
                  className={`menu-navi-inner font-bold ${
                    (prevPage === "news" || prevPage.includes("detail")) && "menu-navi-active"
                  } cursor-pointer`}
                  onClick={() => {
                    if (prevPage === "news") {
                      dispatch(onCloseMenu());
                      return goToTop && goToTop();
                    }

                    if (prevPage.includes("detail")) {
                      if (isScrolling) return;
                      navigate("/news");
                      dispatch(onCloseMenu());
                    }

                    if (prevPage !== "news" && !prevPage.includes("detail")) {
                      if (isScrolling) return;
                      navigate("/news");
                      dispatch(onCloseMenu());
                    }
                  }}
                >
                  뉴스
                </div>
                <div
                  className={`menu-navi-inner font-bold ${
                    prevPage === "inquiry" && "menu-navi-active"
                  } cursor-pointer`}
                  onClick={() => {
                    if (prevPage === "inquiry" && goToTop) {
                      dispatch(onCloseMenu());
                      goToTop();
                    } else {
                      if (isScrolling) return;
                      navigate("/inquiry");
                      dispatch(onCloseMenu());
                      dispatch(onCloseNews(isDesktop));
                    }
                  }}
                >
                  제휴 문의
                </div>
                <div
                  className={`menu-navi-inner font-bold ${
                    prevPage === "faq" && "menu-navi-active"
                  } cursor-pointer`}
                  onClick={() => {
                    if (prevPage === "faq" && goToTop) {
                      dispatch(onCloseMenu());
                      goToTop();
                    } else {
                      if (isScrolling) return;
                      navigate("/faq");
                      dispatch(onCloseMenu());
                      dispatch(onCloseNews(isDesktop));
                    }
                  }}
                >
                  자주 묻는 질문
                </div>
              </div>

              {isTablet && (
                <>
                  <div className="menu-business-container">
                    <div className="menu-business-inner1 font-bold">제휴 문의</div>
                    <div className="menu-business-inner-container">
                      <div className="menu-business-inner2 font-medium">
                        서울특별시 금천구 가산디지털2로 98, 2동 1107호(가산동, 롯데아이티캐슬)
                      </div>
                      <div className="menu-business-inner2 font-medium">Tel. 02-6952-7046</div>
                      <div className="menu-business-inner2 font-medium">
                        E-mail. wingchat.co@gmail.com
                      </div>
                    </div>
                    <div
                      className="menu-business-footer-container cursor-pointer"
                      onClick={() => {
                        if (isScrolling) return;
                        navigate("/inquiry");
                        dispatch(onCloseMenu());
                        dispatch(onCloseNews(isDesktop));
                      }}
                    >
                      <div className="menu-business-footer-container1">
                        <div className="menu-business-footer-inner1">
                          메신저 의뢰 및 업무 제휴 문의
                        </div>
                        <div className="menu-business-arrow" />
                      </div>
                      <div className="menu-business-bottom-bar" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
