import { IFaqs } from "../@types/type";

export const faqs: IFaqs[] = [
  {
    id: 1,
    title: "윙챗은 무엇인가요? 무엇을 할 수 있나요?",
    content: "윙챗은 지갑주소로 결제가 가능한 채팅앱 입니다",
  },

  {
    id: 2,
    title: "개인 정보에 대한 윙챗의 입장은?",
    content: "마케팅 담당자 , 광고주 등과 같은 제3자로 부터 개인 정보를 보호해야 합니다",
  },

  {
    id: 3,
    title: "윙챗의 수익은 어떻게 얻나요?",
    content: "현재는 무료로 이용되고 있으며 추후 수수료를 받을 예정입니다",
  },

  {
    id: 4,
    title: "불법적인 정보가 있습니다. 어떻게 처리하나요?",
    content: "앱에서 신고를 눌러주시거나 고객센터로 연락 주시기 바랍니다",
  },

  {
    id: 5,
    title: "누구에게 메시지를 보낼 수 있나요?",
    content: "친구 추가를 한 유저와 만들거나 초대 된 그룹에서 채팅이 가능합니다",
  },

  {
    id: 6,
    title: "누구에게 포인트를 전송할 수 있나요?",
    content: "친구 추가된 유저와 지갑주소로 포인트 결제가 가능합니다",
  },

  {
    id: 7,
    title: "휴대폰을 도난 당했어요, 어떻게 해야하나요?",
    content:
      "고객 센터에 연락 후 모든 기기의 로그아웃을 요청 해주시거나 , 새로 구입한 휴대폰으로 로그인시 이전 기기는 자동 로그아웃 됩니다",
  },

  {
    id: 8,
    title: "충전후 포인트에 적용이 안되었어요",
    content:
      "약간의 시간차가 발생 할 수 있으며 5분후에도 충전이 되지 않으면 고객센터로 연락바랍니다",
  },

  {
    id: 9,
    title: "포인트 결제를 했다는데 받지를 못했어요",
    content:
      "약간의 시간차가 발생 할 수 있으며 5분후에도 충전이 되지 않으면 고객센터로 연락바랍니다",
  },

  {
    id: 10,
    title: "출금 신청을 하면 소요시간이 얼마나 되나요?",
    content: "은행 점검시간을 제외하고는 5분의 시간차가 생길 수 있습니다",
  },

  {
    id: 11,
    title: "결제 수수료와 출금 수수료는 얼마인가요?",
    content: "결제는 무료이며 출금은 현재는 무료지만 추후 약간의 비용이 나올 수 있습니다",
  },
];
