import "../../../styles/menu/header.css";
import { useNavigate } from "react-router-dom";
import useResponse from "../../../hooks/useResponse";
import { onCloseMenu, onOpenMenu } from "../../../redux/slices/menu";
import { useDispatch } from "react-redux";
import { openStore } from "../../../utils/open";

type Props = {
  color?: "white" | "black";
};

export default ({ color }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();

  const dispatch = useDispatch();

  return (
    <>
      <div className="menu-right-container">
        <div className="menu-download cursor-pointer" onClick={openStore}>
          <span className="menu-download-text font-bold cursor-pointer">앱 다운로드</span>
        </div>
        {/* 닫기 버튼 */}
        <div className="menu-x font-bold cursor-pointer">
          <div
            className="menu-x-inner"
            onClick={() => {
              dispatch(onCloseMenu());
            }}
          ></div>
        </div>
      </div>
    </>
  );
};
