import React, { useEffect, useState } from "react";
import { getPolicy } from "../../apis/services";
import { Viewer } from "@toast-ui/react-editor";

// import PolicyPage from "";

export default () => {
  const [termsContents, setTermsContents] = useState<any>();

  useEffect(() => {
    (async () => {
      const res: any = await getPolicy("전자상거래 표준약관");
      setTermsContents(res);
    })();
  }, []);

  return (
    <div style={{ height: "100vh", overflow: "auto", padding: 15 }}>
      {termsContents && <Viewer usageStatistics={false} initialValue={termsContents.contents} />}
    </div>
  );
};
