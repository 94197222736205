import { combineReducers } from "@reduxjs/toolkit";
import menuSlice from "../slices/menu";
import newsSlice from "../slices/news";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import scrollingSlice from "../slices/scrolling";

const rootPersistConfig = {
  key: "root", // localStorage key
  storage, // localStorage
  whitelist: ["menu", "news", "scrolling"], // target (reducer name)
};

// 위에서 만들었던 slice를 추가한다
const rootReducer = combineReducers({
  menu: menuSlice.reducer,
  news: newsSlice.reducer,
  scrolling: scrollingSlice.reducer,
});

// 타입 에러 막는거
// 이건 그냥 외우거나 복붙하는 수 밖에 없음
export type RootState = ReturnType<typeof rootReducer>;
export default persistReducer(rootPersistConfig, rootReducer);
