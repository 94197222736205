import MainFooter from "../../components/main/MainFooter";
import "../../styles/main/wingchat.css";
import useResponse from "../../hooks/useResponse";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import IntroductionFooter from "../../components/introduction/IntroductionFooter";
import NewsFooter from "../../components/news/NewsFooter";
import FaqFooter from "../../components/faq/FaqFooter";
import useScrolling from "../../hooks/useScrolling";

type Props = {
  move?: (page: string | number) => void;
};

export default ({ move }: Props) => {
  const { isMobile, isTablet, isDesktop } = useResponse();
  const [path, setPath] = useState("");

  const location = useLocation();

  const navigate = useNavigate();

  const { isScrolling } = useScrolling();

  useEffect(() => {
    const newPath = location.pathname.replace("/", "");
    setPath(newPath);
  }, [location]);

  return (
    <div className="wingchat-container">
      {!isMobile && <div className="wingchat-title-text font-bold">WINGCHAT</div>}
      {
        <>
          <div className="big-dots" />
          <div className="small-dots" />
          <div className="wingchat-content">
            <div className="wingchat-title font-light">㈜윙챗</div>
            <div className="wingchat-flex">
              <div>
                <div className="wingchat-container1">
                  <div className="wingchat-inner-title1 font-medium">사업자 등록번호</div>
                  <div className="wingchat-inner-body1 font-light">659-81-03109</div>
                </div>

                <div className="wingchat-container2">
                  <div className="wingchat-inner-title2 font-medium">대표</div>
                  <div className="wingchat-inner-body2 font-light">신성식</div>
                </div>

                <div className="wingchat-container3">
                  <div className="wingchat-inner-title3 font-medium">통신판매업 신고번호</div>
                  <div className="wingchat-inner-body3 font-light">2023-서울금천-1878</div>
                </div>
              </div>

              <div>
                <div className="wingchat-container4">
                  <div className="wingchat-inner-title4 font-medium">주소</div>
                  <div className="wingchat-inner-body4 font-light">
                    서울특별시 금천구 가산디지털2로 98, 2동 1107호(가산동, 롯데아이티캐슬)
                  </div>
                </div>

                <div className="wingchat-container5">
                  <div className="wingchat-inner-title5 font-medium">Tel.</div>
                  <div className="wingchat-inner-body5 font-light">02-6952-7046</div>
                </div>

                <div className="wingchat-container6">
                  <div className="wingchat-inner-title6 font-medium">E-mail</div>
                  <div className="wingchat-inner-body6 font-light">wingchat.co@gmail.com</div>
                </div>
              </div>
            </div>

            <div className="wingchat-copyright font-medium">
              Copyright 2023. Wingchat All rights reserved.
            </div>

            <div className="wingchat-more">
              <div className="wingchat-more-line">
                <div
                  className="wingchat-more-inner1 font-regular cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/policy");
                  }}
                >
                  운영정책
                </div>
                <div
                  className="wingchat-more-inner2 font-medium cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/terms");
                  }}
                >
                  통합 서비스 이용약관
                </div>
                <div
                  className="wingchat-more-inner3 font-medium cursor-pointer"
                  onClick={() => {
                    if (isScrolling) return;
                    navigate("/privacy");
                  }}
                >
                  개인정보 취급방침
                </div>
              </div>

              {/* <div className="wingchat-more-line">
                <div className="wingchat-more-inner4 font-regular cursor-pointer">
                  이용자의 권리 및 유의사항
                </div>
                <div className="wingchat-more-inner5 font-regular cursor-pointer">
                  금융서비스 보호
                </div>
              </div> */}
            </div>
          </div>
          {path === "" && (
            <MainFooter footerMode={"white"} textMode={"white"} active={5} move={move} />
          )}

          {path === "introduction" && (
            <IntroductionFooter footerMode={"white"} textMode={"white"} active={4} move={move} />
          )}

          {(path === "news" || path === "inquiry") && (
            <NewsFooter footerMode={"white"} textMode={"white"} active={3} move={move} />
          )}

          {path === "faq" && (
            <FaqFooter footerMode={"white"} textMode={"white"} active={2} move={move} />
          )}
        </>
      }
    </div>
  );
};
