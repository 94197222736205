import "../../../styles/menu/header.css";
import MenuNavigation from "./MenuNavigation";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onCloseMenu } from "../../../redux/slices/menu";
import useScrolling from "../../../hooks/useScrolling";

export default () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isScrolling } = useScrolling();

  return (
    <div className="menu-header-container">
      <div className="menu-header-inner">
        <div
          className="menu-header-logo cursor-pointer"
          onClick={() => {
            if (isScrolling) return;
            navigate("/");
            dispatch(onCloseMenu());
          }}
        />
        <MenuNavigation />
      </div>
    </div>
  );
};
